import { createContext } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { SnackbarProvider } from "./context/SnackbarProvider";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import "./AdminGlobal.css";

// user
import Invoice from "./Pages/Invoice";
import Home from "./Pages/Home";
import AboutUsPage from "./Pages/About";
import ContactForm from "./Components/Contact/index";
import Listing from "./Pages/Listing";
import ProductDetails from "./Pages/ProductDetails";
import Cart from "./Pages/Cart";
import SignIn from "./Pages/Auth/SignIn";
import SignUp from "./Pages/Auth/SignUp";
import MyList from "./Pages/MyList";
import Checkout from "./Pages/Checkout";
import Orders from "./Pages/Orders";
import MyAccount from "./Pages/MyAccount";
import SearchPage from "./Pages/Search";
import ProductsPage from "./Pages/ProductsPage";

// Admin
import Dashboard from "./admin/pages/Dashboard";
import Products from "./admin/pages/Products";
import Category from "./admin/pages/Category";
import OurProcessPage from "./Pages/OurProcessPage";
import AdminProductDetails from "./admin/pages/ProductDetails";
import ProductUpload from "./admin/pages/Products/sub-pages/addProduct";
import EditProduct from "./admin/pages/Products/sub-pages/editProduct";
import CategoryAdd from "./admin/pages/Category/sub-pages/addCategory";
import EditCategory from "./admin/pages/Category/sub-pages/editCategory";
import SubCatAdd from "./admin/pages/Category/sub-pages/addSubCat";
import SubCatList from "./admin/pages/Category/sub-pages/subCategoryList";
import ProductWeight from "./admin/pages/Products/sub-pages/addProductWeight";
import ProductSize from "./admin/pages/Products/sub-pages/addProductSize";
import AdminOrders from "./admin/pages/Orders";
import AddHomeBannerSlide from "./admin/pages/HomeBanner/sub-pages/addHomeSlide";
import HomeBannerSlideList from "./admin/pages/HomeBanner";
import EditHomeBannerSlide from "./admin/pages/HomeBanner/sub-pages/editSlide";
import TermsAndConditions from "./Pages/Legal/TermsAndConditions";
import PrivacyPolicy from "./Pages/Legal/PrivacyPolicy";
import ContactMessage from "./admin/pages/ContactMessages";
import ListPrivacyPolicy from "./admin/pages/Legal/PivacyPolicy/ListPrivacyPolicy";
import ViewPrivacyPolicyPage from "./admin/pages/Legal/PivacyPolicy/ViewPrivacyPolicy";
import EditPrivacyPolicy from "./admin/pages/Legal/PivacyPolicy/EditPrivacyPolicy";
import AddPrivacyPolicy from "./admin/pages/Legal/PivacyPolicy/AddPrivacyPolicy";
import ListTermsAndConditions from "./admin/pages/Legal/TAndC/ListTAndC";
import ViewTermsAndConditionPage from "./admin/pages/Legal/TAndC/ViewTAndC";
import EditTermsAndConditions from "./admin/pages/Legal/TAndC/EditTAndC";
import AddTermsAndCoditions from "./admin/pages/Legal/TAndC/AddTAndC";

// Layouts
import AdminLayout from "./layouts/AdminLayout";
import ClietLayout from "./layouts/ClientLayout";

// Auth
import Verified from "./Pages/Auth/Verified/index";
import ExpiredVerifition from "./Pages/Auth/VerificationExpired/index";
import AlreadyVerified from "./Pages/Auth/AlreadyVrified/index";
import ForgotPassword from "./Pages/Auth/ForgotPassword/index";
import ForgotPasswordConfirm from "./Pages/Auth/ConfirmForgotPassword/index";

// delhivery
import PincodeServiceability from "./Components/Delhivery/PincodeServiceability";
import FetchWaybill from "./Components/Delhivery/FetchWaybill";
import FetchBulkWaybill from "./Components/Delhivery/FetchBulkWayBill";
import CreateShipment from "./Components/Delhivery/CreateShipment";
import UpdateShipment from "./Components/Delhivery/UpdateShipment";
import TrackShipment from "./Components/Delhivery/TrackShipment";
import CalculateShippingCost from "./Components/Delhivery/CalculateShippingCost";
import CreatePickupRequest from "./Components/Delhivery/CreatePickupRequest";
import CreateWarehouse from "./Components/Delhivery/WarehouseCreation";
import updateWarehouse from "./Components/Delhivery/WarehouseUpdation";
import CreateOrderForm from "./Components/Delhivery/CreateOrder";

// import AddRetailer from "./Pages/Retailer/retailerForm";
// import RetailersList from "./admin/pages/Retailer";

// common
const MyContext = createContext();
const AdminContext = createContext();

function App() {
  return (
    <SnackbarProvider>
      <BrowserRouter>
        <Routes>
          {/* AUTH */}
          <Route path="/auth/verified" exact={true} element={<Verified />} />
          <Route
            path="/auth/expired/link"
            exact={true}
            element={<ExpiredVerifition />}
          />
          <Route
            path="/auth/alreadyverified"
            exact={true}
            element={<AlreadyVerified />}
          />
          <Route
            path="/forgot-password"
            exact={true}
            element={<ForgotPassword />}
          />
          <Route
            path="/forgot-password/:userId/:uniqueString"
            exact={true}
            element={<ForgotPasswordConfirm />}
          />

          {/* DELHIVERY */}
          {/* <Route path="/delhivery/check-pincode" exact={true} element={<PincodeServiceability />} />
          <Route path="/delhivery/fetchwaybill" exact={true} element={<FetchWaybill />} />
          <Route path="/delhivery/fetchbulkwaybill" exact={true} element={<FetchBulkWaybill />} />
          <Route path="/delhivery/calculate-shipping-cost" exact={true} element={<CalculateShippingCost />} />
          <Route path="/delhivery/create-shipment" exact={true} element={<CreateShipment />} />
          <Route path="/delhivery/update-shipment" exact={true} element={<UpdateShipment />} />
          <Route path="/delhivery/track-shipment" exact={true} element={<TrackShipment />} />
          <Route path="/delhivery/create-pickup-request" exact={true} element={<CreatePickupRequest />} />
          <Route path="/delhivery/create-warehouse" exact={true} element={<CreateWarehouse />} />
          <Route path="/delhivery/edit-warehouse" exact={true} element={<updateWarehouse />} />
          <Route path="/delhivery/create-manifestation" exact={true} element={<CreateOrderForm />} /> */}

          {/* client */}
          <Route path="/" element={<ClietLayout></ClietLayout>}>
            <Route path="" exact={true} element={<Home />} />
            <Route path="contact" exact={true} element={<ContactForm />} />
            <Route path="about" exact={true} element={<AboutUsPage />} />
            <Route path="product" exact={true} element={<ProductsPage />} />
            <Route path="process" exact={true} element={<OurProcessPage />} />
            <Route
              path="terms-and-conditions"
              exact={true}
              element={<TermsAndConditions />}
            />
            <Route
              path="privacy-policy"
              exact={true}
              element={<PrivacyPolicy />}
            />
            <Route
              path="products/category/:id"
              exact={true}
              element={<Listing />}
            />
            <Route
              path="products/subCat/:id"
              exact={true}
              element={<Listing />}
            />
            <Route
              path="product/:id"
              exact={true}
              element={<ProductDetails />}
            />
            <Route path="cart" exact={true} element={<Cart />} />
            <Route path="signIn" exact={true} element={<SignIn />} />
            <Route path="signUp" exact={true} element={<SignUp />} />
            <Route path="my-list" exact={true} element={<MyList />} />
            <Route path="checkout" exact={true} element={<Checkout />} />
            <Route path="orders" exact={true} element={<Orders />} />
            <Route
              exact={true}
              path="/orders/invoice/:id"
              element={<Invoice />}
            />
            <Route path="my-account" exact={true} element={<MyAccount />} />
            <Route path="search" exact={true} element={<SearchPage />} />
            {/*  add retailer  */}
            {/* <Route path="retailer/add" exact={true} element={<AddRetailer />} /> */}
          </Route>

          {/* Admin */}
          <Route path="/admin" element={<AdminLayout></AdminLayout>}>
            <Route path="dashboard" exact={true} element={<Dashboard />} />
            <Route path="my-account" exact={true} element={<MyAccount />} />
            <Route
              path="contact-messages"
              exact={true}
              element={<ContactMessage />}
            />
            <Route path="products" exact={true} element={<Products />} />
            <Route
              path="product/details/:id"
              exact={true}
              element={<AdminProductDetails />}
            />
            <Route
              path="product/upload"
              exact={true}
              element={<ProductUpload />}
            />
            <Route
              path="product/edit/:id"
              exact={true}
              element={<EditProduct />}
            />
            <Route path="category" exact={true} element={<Category />} />
            <Route path="category/add" exact={true} element={<CategoryAdd />} />
            <Route path="subCategory/" exact={true} element={<SubCatList />} />
            <Route
              path="subCategory/add"
              exact={true}
              element={<SubCatAdd />}
            />
            <Route
              path="category/edit/:id"
              exact={true}
              element={<EditCategory />}
            />
            <Route
              path="productWEIGHT/add"
              exact={true}
              element={<ProductWeight />}
            />
            <Route
              path="productSIZE/add"
              exact={true}
              element={<ProductSize />}
            />
            <Route path="orders/" exact={true} element={<AdminOrders />} />
            <Route path="orders/invoice/:id" exact={true} element={<Invoice />} />
            <Route path="homeBannerSlide/add" exact={true} element={<AddHomeBannerSlide />} />
            <Route path="homeBannerSlide/list" exact={true} element={<HomeBannerSlideList />} />
            <Route path="homeBannerSlide/edit/:id" exact={true} element={<EditHomeBannerSlide />} />
            <Route path="legal/privacy-policy" exact={true} element={<ListPrivacyPolicy />} />
            {/* <Route path="retailer/add" exact={true} element={<AddRetailer />} />
            <Route path="retailer/list" exact={true} element={<RetailersList />} /> */}
            {/* admin legal */}
            <Route
              path="legal/view-privacy-policy/:id"
              exact={true}
              element={<ViewPrivacyPolicyPage />}
            />
            <Route
              path="legal/edit-privacy-policy/:id"
              exact={true}
              element={<EditPrivacyPolicy />}
            />
            <Route
              path="legal/add-privacy-policy"
              exact={true}
              element={<AddPrivacyPolicy />}
            />
            <Route
              path="legal/terms-and-conditions"
              exact={true}
              element={<ListTermsAndConditions />}
            />
            <Route
              path="legal/view-terms-and-conditions/:id"
              exact={true}
              element={<ViewTermsAndConditionPage />}
            />
            <Route
              path="legal/edit-terms-and-conditions/:id"
              exact={true}
              element={<EditTermsAndConditions />}
            />
            <Route
              path="legal/add-terms-and-conditions"
              exact={true}
              element={<AddTermsAndCoditions />}
            />
          </Route>
        </Routes>
      </BrowserRouter>
    </SnackbarProvider>
  );
}

export default App;

export { MyContext, AdminContext };
