import React from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import AdminAppBar from "./AppBar/AdminAppBar";
import AdminSideBar from "./SideBar/AdminSideBar";
import Toolbar from "@mui/material/Toolbar";
import { Outlet } from "react-router-dom";

const drawerWidth = 240;

export default function AdminLayout() {
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <Box sx={{ display: { xs: "block", md: "flex" } }}>
      <CssBaseline />
      <AdminAppBar handleDrawerToggle={handleDrawerToggle} />
      <AdminSideBar
        mobileOpen={mobileOpen}
        handleDrawerToggle={handleDrawerToggle}
        variant="temporary"
      />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { md: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Toolbar />
        {/* Rendering child components here in Outlet */}
        <Outlet />
      </Box>
    </Box>
  );
}
