import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ListItemButton from "@mui/material/ListItemButton";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import "./AdminSideBar.css";

import logo from "../../assets/images/logo.png";

// Icons
import { RxDashboard } from "react-icons/rx";
import { FiShoppingCart } from "react-icons/fi";
import { TbBrandProducthunt } from "react-icons/tb";
import { RiListCheck2 } from "react-icons/ri";
import { LiaCloudUploadAltSolid } from "react-icons/lia";
import { TbWeight } from "react-icons/tb";
import { IoResizeOutline } from "react-icons/io5";
import { MdOutlineCategory } from "react-icons/md";
import { RiListIndefinite } from "react-icons/ri";
import { TbCategoryPlus } from "react-icons/tb";
import { RiListUnordered } from "react-icons/ri";
import { MdAdd } from "react-icons/md";
import { AiOutlineMessage } from "react-icons/ai";
import { TfiLayoutSliderAlt } from "react-icons/tfi";
import { BiSliderAlt } from "react-icons/bi";
import { BsShop } from "react-icons/bs";
import { TbPlaylistAdd } from "react-icons/tb";
import { MdOutlinePrivacyTip, MdOutlinePolicy } from "react-icons/md";

const drawerWidth = 250;

const AdminSideBar = ({ mobileOpen, handleDrawerToggle, variant }) => {
  const [openDropdown, setOpenDropdown] = React.useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  const handleDropdownClick = (menu) => {
    setOpenDropdown(openDropdown === menu ? null : menu);
  };

  const isActiveRoute = (route) => {
    return location.pathname === route;
  };

  const drawer = (
    <Box className="drawer">
      {/* Drawer Header */}
      <Box className="drawerHeader" onClick={() => navigate("/")}>
        <img
          src={logo}
          alt="Logo"
          height={40}
          style={{ background: "rgba(0, 0, 0, 0.8)", borderRadius: "50%" }}
        />
        <small style={{ fontWeight: "bold", color: "rgba(0, 0, 0, 0.8)" }}>
          Royal BeeKeeper
        </small>
      </Box>

      <Divider />

      {/* Drawer Content */}
      <Box className="drawerContent">
        <List>
          {/* Overview */}
          <Box className="submenuContainer firstSubmenuContainer">
            <small className="submenuTitle">Overview</small>

            <ListItemButton
              selected={isActiveRoute("/admin/dashboard")}
              onClick={() => navigate("/admin/dashboard")}
              className={
                isActiveRoute("/admin/dashboard") ? "activeButton" : ""
              }
            >
              <ListItemIcon className="listItemIcon">
                <RxDashboard size={18} />
              </ListItemIcon>
              <ListItemText primary="Dashboard" />
            </ListItemButton>

            <ListItemButton
              selected={isActiveRoute("/admin/orders")}
              onClick={() => navigate("/admin/orders")}
              className={isActiveRoute("/admin/orders") ? "activeButton" : ""}
            >
              <ListItemIcon className="listItemIcon">
                <FiShoppingCart size={18} />
              </ListItemIcon>
              <ListItemText primary="Orders" />
            </ListItemButton>
          </Box>

          {/* Options */}
          <Box className="submenuContainer">
            <small className="submenuTitle">Options</small>

            {/* Products */}
            <ListItemButton onClick={() => handleDropdownClick("products")}>
              <ListItemIcon className="listItemIcon">
                <TbBrandProducthunt size={18} />
              </ListItemIcon>
              <ListItemText primary="Products" />
              {openDropdown === "products" ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>

            <Collapse
              in={openDropdown === "products"}
              timeout="auto"
              unmountOnExit
            >
              <List component="div" disablePadding>
                <ListItemButton
                  selected={isActiveRoute("/admin/products")}
                  onClick={() => navigate("/admin/products")}
                  className={
                    isActiveRoute("/admin/products")
                      ? "subButton activeButton"
                      : "subButton"
                  }
                >
                  <ListItemIcon className="listItemIcon">
                    <RiListCheck2 />
                  </ListItemIcon>
                  <ListItemText primary="Product List" />
                </ListItemButton>

                <ListItemButton
                  selected={isActiveRoute("/admin/product/upload")}
                  onClick={() => navigate("/admin/product/upload")}
                  className={
                    isActiveRoute("/admin/product/upload")
                      ? "subButton activeButton"
                      : "subButton"
                  }
                >
                  <ListItemIcon className="listItemIcon">
                    <LiaCloudUploadAltSolid />
                  </ListItemIcon>
                  <ListItemText primary="Upload New Product" />
                </ListItemButton>

                <ListItemButton
                  selected={isActiveRoute("/admin/productWEIGHT/add")}
                  onClick={() => navigate("/admin/productWEIGHT/add")}
                  className={
                    isActiveRoute("/admin/productWEIGHT/add")
                      ? "subButton activeButton"
                      : "subButton"
                  }
                >
                  <ListItemIcon className="listItemIcon">
                    <TbWeight />
                  </ListItemIcon>
                  <ListItemText primary="Add Product WEIGHT" />
                </ListItemButton>

                <ListItemButton
                  selected={isActiveRoute("/admin/productSIZE/add")}
                  onClick={() => navigate("/admin/productSIZE/add")}
                  className={
                    isActiveRoute("/admin/productSIZE/add")
                      ? "subButton activeButton"
                      : "subButton"
                  }
                >
                  <ListItemIcon className="listItemIcon">
                    <IoResizeOutline />
                  </ListItemIcon>
                  <ListItemText primary="Add Product SIZE" />
                </ListItemButton>
              </List>
            </Collapse>

            {/* Category */}
            <ListItemButton onClick={() => handleDropdownClick("banners")}>
              <ListItemIcon className="listItemIcon">
                <MdOutlineCategory size={18} />
              </ListItemIcon>
              <ListItemText primary="Category" />
              {openDropdown === "banners" ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>

            <Collapse
              in={openDropdown === "banners"}
              timeout="auto"
              unmountOnExit
            >
              <List component="div" disablePadding>
                <ListItemButton
                  selected={isActiveRoute("/admin/category")}
                  onClick={() => navigate("/admin/category")}
                  className={
                    isActiveRoute("/admin/category")
                      ? "subButton activeButton"
                      : "subButton"
                  }
                >
                  <ListItemIcon className="listItemIcon">
                    <RiListIndefinite />
                  </ListItemIcon>
                  <ListItemText primary="Category List" />
                </ListItemButton>

                <ListItemButton
                  selected={isActiveRoute("/admin/category/add")}
                  onClick={() => navigate("/admin/category/add")}
                  className={
                    isActiveRoute("/admin/category/add")
                      ? "subButton activeButton"
                      : "subButton"
                  }
                >
                  <ListItemIcon className="listItemIcon">
                    <TbCategoryPlus />
                  </ListItemIcon>
                  <ListItemText primary="Add a category" />
                </ListItemButton>

                <ListItemButton
                  selected={isActiveRoute("/admin/subCategory")}
                  onClick={() => navigate("/admin/subCategory")}
                  className={
                    isActiveRoute("/admin/subCategory")
                      ? "subButton activeButton"
                      : "subButton"
                  }
                >
                  <ListItemIcon className="listItemIcon">
                    <RiListUnordered />
                  </ListItemIcon>
                  <ListItemText primary="Sub Category List" />
                </ListItemButton>

                <ListItemButton
                  selected={isActiveRoute("/admin/subCategory/add")}
                  onClick={() => navigate("/admin/subCategory/add")}
                  className={
                    isActiveRoute("/admin/subCategory/add")
                      ? "subButton activeButton"
                      : "subButton"
                  }
                >
                  <ListItemIcon className="listItemIcon">
                    <MdAdd />
                  </ListItemIcon>
                  <ListItemText primary="Add a sub category" />
                </ListItemButton>
              </List>
            </Collapse>

            {/* Banners */}
            <ListItemButton onClick={() => handleDropdownClick("gallery")}>
              <ListItemIcon className="listItemIcon">
                <TfiLayoutSliderAlt size={18} />
              </ListItemIcon>
              <ListItemText primary="Home Banners" />
              {openDropdown === "gallery" ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>

            <Collapse
              in={openDropdown === "gallery"}
              timeout="auto"
              unmountOnExit
            >
              <List component="div" disablePadding>
                <ListItemButton
                  selected={isActiveRoute("/admin/homeBannerSlide/add")}
                  onClick={() => navigate("/admin/homeBannerSlide/add")}
                  className={
                    isActiveRoute("/admin/homeBannerSlide/add")
                      ? "subButton activeButton"
                      : "subButton"
                  }
                >
                  <ListItemIcon className="listItemIcon">
                    <TbPlaylistAdd />
                  </ListItemIcon>
                  <ListItemText primary="Add Home Banner" />
                </ListItemButton>

                <ListItemButton
                  selected={isActiveRoute("/admin/homeBannerSlide/list")}
                  onClick={() => navigate("/admin/homeBannerSlide/list")}
                  className={
                    isActiveRoute("/admin/homeBannerSlide/list")
                      ? "subButton activeButton"
                      : "subButton"
                  }
                >
                  <ListItemIcon className="listItemIcon">
                    <BiSliderAlt />
                  </ListItemIcon>
                  <ListItemText primary="List All Banners" />
                </ListItemButton>
              </List>
            </Collapse>
            {/* RETAILERS */}
            {/* <ListItemButton onClick={() => handleDropdownClick("Retailer")}>
              <ListItemIcon className="listItemIcon">
                <BsShop size={18} />
              </ListItemIcon>
              <ListItemText primary="Retailers" />
              {openDropdown === "Retailer" ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>

            <Collapse
              in={openDropdown === "Retailer"}
              timeout="auto"
              unmountOnExit
            >
              <List component="div" disablePadding>
                <ListItemButton
                  selected={isActiveRoute("/admin/retailer/list")}
                  onClick={() => navigate("/admin/retailer/list")}
                  className={
                    isActiveRoute("/admin/retailer/list")
                      ? "subButton activeButton"
                      : "subButton"
                  }
                >
                  <ListItemIcon className="listItemIcon">
                    <BiSliderAlt />
                  </ListItemIcon>
                  <ListItemText primary="List All Retailers" />
                </ListItemButton>
              </List>
            </Collapse> */}
          </Box>

          {/* User and Feedback */}
          <Box className="submenuContainer">
            <small className="submenuTitle">User and Feedback</small>

            <ListItemButton
              selected={isActiveRoute(`/admin/contact-messages`)}
              onClick={() => navigate(`/admin/contact-messages`)}
              className={
                isActiveRoute(`/admin/contact-messages`) ? "activeButton" : ""
              }
            >
              <ListItemIcon className="listItemIcon">
                <AiOutlineMessage size={18} />
              </ListItemIcon>
              <ListItemText primary="Contact Messages" />
            </ListItemButton>
          </Box>

          {/* Legal */}
          <Box className="submenuContainer lastSubmenuContainer">
            <small className="submenuTitle">Legal</small>

            <ListItemButton
              selected={isActiveRoute(`/admin/legal/privacy-policy`)}
              onClick={() => navigate(`/admin/legal/privacy-policy`)}
              className={
                isActiveRoute(`/admin/legal/privacy-policy`)
                  ? "activeButton"
                  : ""
              }
            >
              <ListItemIcon className="listItemIcon">
                <MdOutlinePolicy size={18} />
              </ListItemIcon>
              <ListItemText primary="Privacy Policy" />
            </ListItemButton>

            <ListItemButton
              selected={isActiveRoute(`/admin/legal/terms-and-conditions`)}
              onClick={() => navigate(`/admin/legal/terms-and-conditions`)}
              className={
                isActiveRoute(`/admin/legal/terms-and-conditions`)
                  ? "activeButton"
                  : ""
              }
            >
              <ListItemIcon className="listItemIcon">
                <MdOutlinePrivacyTip size={18} />
              </ListItemIcon>
              <ListItemText primary="T&C" className="listItemText" />
            </ListItemButton>
          </Box>
        </List>
      </Box>

      <Divider />
    </Box>
  );

  return (
    <Box
      sx={{
        width: { md: drawerWidth },
        flexShrink: { md: 0 },
        height: "100%",
      }}
      aria-label="mailbox folders"
    >
      {/* The responsive drawer */}
      <Drawer
        variant={variant}
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { xs: "block", sm: "block", md: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
          },
          height: "100%",
        }}
      >
        {drawer}
      </Drawer>

      {/* The permanent drawer */}
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: "none", sm: "none", md: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
            height: "100vh",
          },
        }}
        open
      >
        {drawer}
      </Drawer>
    </Box>
  );
};

export default AdminSideBar;
